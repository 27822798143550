import React from 'react'
import { NavWithLogoOnly } from '../../components/ui/navigation/navigation'
import Container from '../../components/ui/container'
import * as styles from './referral.module.css'

import "../base.css"
export default class Referral extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#ffffff' }}>
        <NavWithLogoOnly />
        <Container>
          <h2 className="text-3xl mb-8">Wagetap Referral Competition - Terms & Conditions </h2>

          <ReferralBlock>
            <h6>Schedule</h6>
            <br></br>
            <table>
              <tr>
                <td>Promotion</td>
                <td>Wagetap Referral Competition</td>
              </tr>
              <tr>
                <td>Promoter</td>
                <td>Wagetap Lending Pty Ltd ACN 662 713 608 trading as Wagetap.<br/>
                    476-478 George St, Sydney NSW 2000<br/>
                    <a href='mailto:hello@wagetap.com' className='underline'>hello@wagetap.com</a><br/>
                    <a href='https://www.wagetap.com' className='underline'>www.wagetap.com</a>
                </td>
              </tr>
              <tr>
                <td>Promotional period</td>
                <td>Start Date: <br/>
                    9th March 2023 <br/>
                    End Date: <br/>
                    10th April 2023
                </td>
              </tr>
              <tr>
                <td>Prize</td>
                <td>1 x $1,000 cash prize to be deposited into the selected winner’s bank account connected with Wagetap.</td>
              </tr>
              <tr>
                <td>Relevant States</td>
                <td>All states in Australia.</td>
              </tr>
              <tr>
                <td>Entry Procedure</td>
                <td>1x entry for referrer (existing Wagetap user)  and 1x entry for referee (Wagetap user who has not made a withdrawal prior to the promotional period) once the referee has made a successful withdrawal or bill split within the promotional period. </td>
              </tr>
              <tr>
                <td>Maximum Number of Entries</td>
                <td>Unlimited entries.</td>
              </tr>
              <tr>
                <td>Draw Details</td>
                <td>Draw Date: 11th April 2023<br/>
                  Draw Time: 12pm AEST<br/>
                  Draw Method: Electronic random draw</td>
              </tr>
              <tr>
                <td>Privacy Policy</td>
                <td>
                  <a
                    className="underline"
                    href='https://www.wagetap.com/assets/privacy_policy_20230720.pdf'>
                      www.wagetap.com/assets/privacy_policy_20230720.pdf
                  </a>
                </td>
              </tr>
            </table>
          </ReferralBlock>

          <ReferralBlock>
            <h6>Terms and Conditions</h6>
            <ol>
              <li>1. Information on eligibility, mechanisms on how to enter, prizes and the information in the Schedule form part of these Terms and Conditions. Participation in the Promotion is deemed acceptance of these Terms and Conditions.</li>
              <li>2. Any capitalised terms used in these Terms & Conditions have the meaning given in the Schedule, except where stated otherwise.</li>
            </ol>
          </ReferralBlock>

          <ReferralBlock>
            <h6>Eligibility & Entry</h6>
            <ol>
              <li>3. Entry is open to residents of the Relevant States aged eighteen (18) years or over (Entrants). Employees, immediate family members, retailers, suppliers, associated companies and agencies related to the Promoter are not eligible to enter in the Promotion.</li>
              <li>4. To enter the Promotion, Entrants must complete the Entry Procedure during the Promotional Period. Entries may only be submitted in accordance with the Entry Procedure and will not be accepted by the Promoter in any other form.</li>
              <li>5. Entrants may enter the Promotion up to the Maximum Number of Entries.</li>
              <li>6. Entries must be received by the Promoter during the Promotion Period. Online or email entries are deemed to have been received at the time of receipt by the Promoter and not at the time of transmission.</li>
              <li>7. The use of automated entry software or other mechanical, electronic or other means that allow an Entrant to automatically enter the Promotion repeatedly is prohibited and will render all entries submitted by the Entrant invalid.</li>
              <li>8. Incomplete and ineligible entries will be deemed invalid. Entries will also be deemed invalid if they breach these Terms & Conditions as determined by the Promoter in its sole discretion or any other content guidelines notified by the Promoter during completion of the Entrant’s completion of the Entry Procedure.</li>
              <li>9. The Promoter reserves the right, at any time, to verify the validity of entries and Entrants (including an Entrant’s identity, age and place of residence) and to disqualify any Entrant. Failure by the Promoter to enforce any of its rights at any stage does not constitute a waiver of those rights.</li>
            </ol>
          </ReferralBlock>

          <ReferralBlock>
            <h6>Prize Draw</h6>
            <ol>
              <li>10. The Prize draw to determine the winning Entrant for the Promotion will be conducted in accordance with the Draw Details. The Prize(s) will be awarded to the Entrant or Entrants (as applicable) randomly drawn in accordance with the Draw Details, provided the Entrant has complied with the Entry Procedure.</li>
              <li>11. The winning Entrant will be notified by email and/or telephone within forty eight (48) hours of the Prize Draw.</li>
              <li>12. The winning Entrant’s full name and city of origin will be published in a national newspaper, and/or on the Site, or on the Promoter’s social media accounts, including but not limited to, Facebook, Twitter and Instagram. It is a condition of entry into the Promotion that the winning Entrant consents to the publication of such information and participates in any media releases which may include photographs of the winning Entrant by the Promoter. By entering into this Promotion, Entrants consent to the use of their names and likenesses in this manner.</li>
              <li>13. Entrants are responsible for any and all expenses incurred when entering the Promotion and accessing, claiming and/or using the Prize (unless otherwise stated on the Site).</li>
              <li>14. As the Promotion is a game of chance, and subject otherwise to these Terms & Conditions, skill plays no part in determining the winning Entrant and each valid entry will NOT be individually judged. Each entry has an equal chance of winning. The winning Entrant will be confirmed by the Promoter. The Promoter’s decision is final and no correspondence will be entered into with Entrants regarding the decision.</li>
              <li>15. In the event that a winning Entrant’s entry is deemed or found to be invalid, the Promoter may redraw or decide on another winning entry.</li>
            </ol>
          </ReferralBlock>

          <ReferralBlock>
            <h6>Prizes</h6>
            <ol>
              <li>16. The Prize(s) are specified in the Schedule. THE PRIZE IS NOT TRANSFERRABLE AND NOT REDEEMABLE FOR CASH.</li>
              <li>17. If any Prize is unavailable and the Promoter has used all reasonable efforts to arrange the Prize, the Promoter may, in its absolute discretion, reserve the right to substitute the Prize with a prize of equal value and/or specification, subject to any written directions from a regulatory authority.</li>
              <li>18. (Cash) Where cash is awarded as a Prize, the Prize will be awarded in the form of electronic funds transfer to the winning Entrant’s nominated Wagetap bank account, at the Promoter’s election.</li>
            </ol>
          </ReferralBlock>

          <ReferralBlock>
            <h6>Claiming Prizes</h6>
            <ol>
              <li>19. The Prize(s) must be claimed by the Redemption Date in accordance with any claim instructions set out in the Schedule. The Promoter may require the winning Entrant to provide relevant evidence in order to claim the Prize, such as proof of identity, age, and any relevant proof of purchase.</li>
              <li>20. If the winning Entrant does not claim the Prize before the Redemption Date, the Prize will be deemed to be forfeited by the Entrant.</li>
              <li>21. If any Prize remain unclaimed in accordance with clause 19, a second draw or selection for the Prize (Unclaimed Prize Draw), will take place on the first business day after the expiry of the Redemption Period at the same time, place and manner as the original Draw Details, subject to any directions from a regulatory authority.  The alternative winning Entrant, if any, will be notified by email and/or telephone within forty eight (48) hours of the Unclaimed Prize Draw.</li>
            </ol>
          </ReferralBlock>

          <ReferralBlock>
            <h6>General</h6>
            <ol>
              <li>22. Personal information: The Promoter collects personal information from all Entrants in order to conduct the Promotion and may, for this purpose, collect, use and disclose such information to third parties, including but not limited to agents, contractors, service providers, Prize suppliers and as required, to regulatory authorities. Please see the Promoter’s Privacy Policy (linked in the Schedule) for more information about how the Promoter collects, stores, uses and discloses personal information, including details about overseas disclosure, access, correction, how Entrants can make a privacy-related complaint and the Promoter’s complaint-handling process. By providing personal information to the Promoter, the Entrant agrees to the collection, use, storage and disclosure of that information as described in this clause 22 and the Promoter’s Privacy Policy.</li>
              <li>23. Non-Excludable Guarantees: Nothing in these Terms and Conditions limits, excludes or modifies or purports to limit, exclude or modify any legislation which cannot lawfully be excluded or limited, including the statutory consumer guarantees as provided under the Competition and Consumer Act 2010 (Cth) or any other applicable State or Territory legislation (Non-Excludable Guarantees).</li>
              <li>24. Consequential Loss: Despite anything to the contrary, to the maximum extent permitted by law, the Promoter will not be liable under these Terms & Conditions for any consequential loss including but not limited to loss of profit (including anticipated profit), loss of benefit (including anticipated benefit), loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings (including anticipated savings), loss of reputation, loss of use (including both real and anticipatory) and/ or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.</li>
              <li>25. Liability: Except for any liability that cannot be excluded by law (including the Non-Excludable Guarantees), the Promoter (including its respective officers, employees and agents) will not be liable to an Entrant for, and the Entrant waives and releases the Promoter in respect of all liability (including but not limited to all expenses, costs, damages, claims, losses) arising in any way out of or in connection with:</li>
              <li>
                <ol>
                  <li>(a) any act or omission of an Entrant;</li>
                  <li>(b) any breach of these Terms & Conditions by the Entrant;</li>
                  <li>(c) any personal injury or death or property loss or damage;</li>
                  <li>(d) claiming the Prize;</li>
                  <li>(e) use of the Prize in any way;</li>
                  <li>(f) any theft of any Prize or unauthorised access or third party interference in the Promotion;</li>
                  <li>(g) any entry or Prize claim that is late, lost, altered, damaged, delayed, corrupted or misdirected (whether or not after their receipt by the Promoter) due to any reason beyond the reasonable control of the Promoter; or</li>
                  <li>(h) any tax liability incurred by a winning Entrant, except to the extent such liability was caused or contributed to by the Promoter’s negligent act or negligent omission.</li>
                </ol>
              </li>
              <li>26. Force Majeure: Neither Party will be liable for any delay or failure to perform their respective obligations under this Agreement if such delay or failure is caused or contributed to by a Force Majeure Event. Force Majeure means any event or circumstance which is beyond a Party’s reasonable control including but not limited to, acts of God including fire, hurricane, typhoon, earthquake, landslide, tsunami, mudslide or other catastrophic natural disaster, civil riot, civil rebellion, revolution, terrorism, insurrection, militarily usurped power, act of sabotage, act of a public enemy, war (whether declared or not) or other like hostilities, ionising radiation, contamination by radioactivity, nuclear, chemical or biological contamination, any widespread illness, quarantine or government sanctioned ordinance or shutdown, pandemic (including COVID-19 and any variations or mutations to this disease or illness) or epidemic.</li>
              <li>27. Online entries: In the event that a dispute arises as to the identity of an online Entrant, the entry will be deemed to have been submitted by the authorised account holder of the email address. The authorised account holder means the natural person who is assigned to an email address by an Internet access provider, online service provider, or other organisation that is responsible for assigning email addresses for the domain associated with the email. The Promoter may ask any Entrant to provide the Promoter with proof that such party is the authorised account holder of the email address associated with the entry.</li>
              <li>28. Legal Warning: Any attempt, deliberate or otherwise, to cause malicious damage or interference with the normal functioning of the Site, or the information on the Site, or to otherwise undermine the legitimate operations of the Promotion may be a violation of criminal and civil laws and, should such an attempt be made, whether successful or not, the Promoter reserves the right to seek damages to the fullest extent permitted by law. If the Promoter suffers loss or incurs any costs in connection with any breach of these Terms and Conditions or any other legal obligation by an Entrant, the Entrant agrees to indemnify the Promoter for those losses, damages and costs. </li>
              <li>29. Social Media: Social media membership pages, applications and use of social media generally is subject to the prevailing terms and conditions of use of the social media platform. Unless otherwise indicated in these Terms and Conditions, the Promotion is in no way sponsored, endorsed or administered by, or associated with, any social media platform including, but not limited to, Instagram, Facebook, Twitter and Pinterest. If an Entrant uses social media to participate in the Promotion, the Entrant understands that they are providing their information to the Promoter and not to any social media platform. Entrants are solely responsible and liable for the content of their entries and any other information they transmit to other Internet users. By participating in the Promotion, the Entrant releases Instagram, Facebook, Twitter, Pinterest or applicable social media site from all claims, liabilities, suits, actions and expenses, including costs of litigation and reasonable legal costs associate with the Promotion. </li>
              <li>30. Currency: Unless the contrary intention appears, a reference in these terms or in any advertisement relating to the Promotion, to Australian dollars, dollars, AUD$ or $ is a reference to the lawful currency of Australia.</li>
              <li>31. Amendments: These Terms and Conditions may be amended or replaced from time to time if required by any regulatory authority.</li>
              <li>32. Jurisdiction: These Terms and Conditions are governed by the laws of the States and Territories in which the Promotion is conducted and the Commonwealth of Australia. Entrants submit to the jurisdiction of the courts of their State or Territory.</li>
              <li>33. Entire Agreement: These Terms and Conditions represent the entire agreement between the Promoter and any Entrant (including the winning Entrant) with respect to its subject matter and supersede any prior agreement, understanding or arrangement between the Promoter and any Entrant (including the winning Entrant), whether oral or in writing.</li>
              <li>34. Representations: The Promoter excludes all, warranties, guarantees or representations (whether express or implied) except as expressly provided in these Terms and Conditions.</li>
              <li>35. Last updated: 31 Jan 2023</li>
            </ol>
          </ReferralBlock>
        </Container>
      </div>
    )
  }
}

const ReferralBlock = (props) => (
  <div className={styles.referralBlock}>{props.children}</div>
)
